import { useRouter } from 'next/router';
import Loading from '../components/common/loading';
import AuthForm from '../components/forms/auth-form';
import { Container } from '../components/layout/container';
import Layout from '../components/layout/layout';
import { useUser } from '../components/user-context';

const AuthInner = () => {
  const router = useRouter();
  const { userInitialized, isLoggedIn } = useUser();

  if (isLoggedIn) {
    router.push('/');
  }

  return (
    <>
      {userInitialized ? (
        <Container>
          <AuthForm className='mt-10' />
        </Container>
      ) : (
        <Container>
          <Loading />
        </Container>
      )}
    </>
  );
};

const Auth = ({ preview }) => (
  <Layout preview={preview} robots='noindex'>
    <AuthInner />
  </Layout>
);

export default Auth;

export async function getStaticProps({ preview = false }) {
  return {
    props: { preview },
  };
}
